<template>
   <div>
     <v-banner single-line class="banner">
       <v-img
           src="https://h5.ophyer.cn/official_website/banner/h5IndustrialManufacturing-banner1.png"
           height="100%"
           :transition="false"
           class="d-flex align-center"

       >
         <template v-slot:placeholder>
           <v-row class="fill-height ma-0" align="center" justify="center">
             <v-progress-circular
                 indeterminate
                 size="80"
                 color="red lighten-5"
             ></v-progress-circular>
           </v-row>
         </template>
          <v-container color="transparent" class="d-flex  max-width-1200">
           <v-card color="transparent" flat >
             <div class="d-flex flex-no-wrap justify-space-between ml-4">
               <div>
                 <v-card-title class="white--text h5-banner-title font-size-h5banner-title" >
                   智慧工厂
                 </v-card-title>
               </div>
             </div>
           </v-card>
         </v-container>
       </v-img>
     </v-banner>
     <!-- 功能 -->
     <v-card color="#2181EA" flat class="py-6 position-r">
       <v-card-title class="justify-center font-size-20 color-FFF pa-0 line-height-28">功能</v-card-title>
       <v-tabs v-model="tab" background-color="transparent" color="white" dark
               class="xs-tab">
         <v-tab v-for="(v, k) in list" :key="k" class="pa-0"
                :class="[k === 0 ? 'ml-5':'ml-6',k === list.length - 1 ? 'mr-5':'']"
                style="min-width: unset">
           <b class="color-FFF font-size-16 letter-spacing" style="font-weight: 400">{{ v.title }}</b>
         </v-tab>
       </v-tabs>
       <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
         <v-tab-item v-for="(v, k) in list" :key="k" :transition="false">
           <v-card flat color="white" class="pa-5">
             <v-img :src="v.img" width="100%" min-height="200" contain>
               <template v-slot:placeholder><img-placeholder></img-placeholder></template>
             </v-img>
             <v-card-title class="pa-0 mt-4 font-size-18 line-height-25">{{v.h2}}</v-card-title>
             <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.content" />
           </v-card>
         </v-tab-item>
       </v-tabs-items>
       <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
       <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
     </v-card>

    <!-- 方案优势 -->
     <v-card flat class="h5model_list1">
       <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 line-height-28">方案优势</v-card-title>
       <div class="px-3 mt-3">
         <v-row v-for="(v,k) in modelList2" :key="k" class="justify-center mt-0">
           <div class="model_item">
             <img class="item_img" :src='v.icon' />
             <div class="item_info">
               <v-card-title class="justify-center reset-title font-size-16">{{ v.title }}</v-card-title>
               <v-card-text class="reset-text">{{ v.text }}</v-card-text>
             </div>
           </div>
         </v-row>
       </div>
     </v-card>
    <!-- 展示终端 -->
     <v-card class="justify-center h5model_list3" flat style="padding: 10px 0 0;background: #FBFBFB;">
       <v-card-title class="justify-center font-size-20 color-333 pa-0 mt-6 mb-6 line-height-28">展示终端</v-card-title>
       <div class="con">
         <v-row>
           <v-col
               v-for="(n,index) in modelList3"
               :key="index"
               class="d-flex child-flex"
               cols="12"
           >
             <div class="box-img" :style="{backgroundImage: 'url('+n.img+')'}">
               <div class="tit">
                 <div class="center">
                   <p class="p1">{{ n.title }}</p>
                   <p class="p2">{{ n.text }}</p>
                 </div>
               </div>
             </div>
           </v-col>
         </v-row>
       </div>
     </v-card>
   </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "LearnCloud",
  data(){
    return{
      model: 0,
      tab:null,
      bannerLoad: false,
      imgLoad: false,
      windowHight: window.innerHeight/2,
      list:[
        {title:"空间漫游",h2:'空间漫游',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-1.png', content:'用户可不受限制，自由在3D模式浏览'},
        {title:"资源快速定位",h2:'资源快速定位',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-2.png',
          content:'三维模型通过搜索可以快速对人员、物体、监控探头定位，并弹出对应信息及附近可用资源信息'},
        {title:"智能巡更",h2:'智能巡更',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-3.png',
          content:'在三维场景中设定自定义巡更路线，巡更过程中可以自动弹出巡更路线附近监控图像，方便巡更人员发现并迅速定位异常'},
        {title:"视频监控",h2:'视频监控',img:'https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-4.png',
          content:'GIS实现对单位中各种监控设备的控制，用户可在监控系统中实时查看控制、查看监控设备'},
      ],
      modelList2: [
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-1.png",
          title: "高效管理",
          text: "资源快速定位，参数实时监控，大大提升管理效率"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-2.png",
          title: "挖掘生产潜力",
          text: "工厂的运行状态可被实时监控，各环节的不足可被即可定位并优化"
        },
        {
          icon: "https://h5.ophyer.cn/official_website/icon/num-icon-IndustrialManufacturing-3.png",
          title: "降低生产风险",
          text: "智能巡更配合视频监控，快速发现风险并定位问题关键点"
        }
      ],
      modelList3: [
        {
          img: "https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-5.png",
          title: "手机电脑端",
          text: "可以在线上转发传播"
        },
        {
          img: "https://h5.ophyer.cn/official_website/other/num-IndustrialManufacturing-img-6.png",
          title: "飞天立体交互墙",
          text: "在小间距LED的基础上增加交互装置 打造了替代投影教学方案的全新沉浸式教学系统"
        },
      ],
      solutions: [
        { title: 'VR播控系统', src: 'https://h5.ophyer.cn/official_website/other/learnimg31.jpg', content:'用于教学/实验、拓展体验，方案灵活，<br/>易落地',anli:'bokongxitong'},
        { title: '全景运营创业培训', src: 'https://h5.ophyer.cn/official_website/other/learnimg32.jpg', content:'设备使用教学+全景制作工具培训，享受<br/>平台派单，创业如此简单',anli:'chuangyepeixun'},
      ],
      advantage:[
        { title: '国家政策扶持', src: 'https://h5.ophyer.cn/official_website/other/learnYS1.png', content:'匹配教育部对虚拟现实专业的建设要求'},
        { title: '大平台技术支撑', src: 'https://h5.ophyer.cn/official_website/other/learnYS2.png', content:'虚拟现实应用技术引领者，打造VR<br/>虚拟现实教学平台'},
        { title: '教学资源丰富', src: 'https://h5.ophyer.cn/official_website/other/learnYS3.png', content:'对接主流教育行业，拥有各专业丰富的<br/>教学资源'},
        { title: '完善的课程体系', src: 'https://h5.ophyer.cn/official_website/other/learnYS4.png', content:'完善的课程体系，打造一站式智慧<br/>教育平台'},
        { title: '满足多场景教学', src: 'https://h5.ophyer.cn/official_website/other/learnYS5.png', content:'满足教、学、练、测、评的需求，全场景<br/>教学，提升学习效率'},
        { title: '专业拓展', src: 'https://h5.ophyer.cn/official_website/other/learnYS6.png', content:'依托虚拟现实技术，支持各专业虚拟仿真<br/>训练室的建设'},
      ]
    }
  },
  mounted(){
    this.bannerLoad = true;
  },
  methods: {
    handleLoad (){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
    handleTabBtn: function (type){
      const maxTab = this.list.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .v-card__subtitle, .v-card__text ,.text-subtitle-1{
  line-height: 26px !important;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.h5model_list1 {
  width: 100%;
  position: relative;
  .model_item{
    width: 300px;
    background: #FFFFFF;
    border-radius: 16px;
    padding: 15px 12px;
    text-align: center;
    .item_img{
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
    .item_info{
      margin-top: 10px;
    }
  }
}
.h5model_list3{
  width: 1200px;
  margin: 0 auto;
  .public-title {
    margin-top: 57px !important;
  }
  .con {
    margin: 25px;
    .box-img {
      width: 300px;
      height: 210px;
      background-size: cover;
      overflow: hidden;
      transition: all 0.3s ease-in;
      border-radius: 10px;
      .tit{
        background-color: rgba(25, 26, 27, 0.5);
        width: 100%;
        height: 100%;
        position: relative;
        color: #fff;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .center{
        position: absolute;
        padding: 12px;
        text-align: center;
        .p1{
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          color: #FFFFFF;
          line-height: 27px;
        }
        .p2{
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 16px;
        }
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
::v-deep.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF !important;
  // padding-top: 10%;
}
.scence_text{
  color: #FFFFFF !important;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #2672FF;
  font-size: 20px;
}
::v-deep.solutions_row{
  width: 960px;
  margin: 0 auto;
}
.warp {
  margin: 83px auto 0;
  text-align: center;
}

.warp2 {
  .box {
    width: 1024px;
    margin: 50px auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
      object-fit: cover
    }
  }
  .right {
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    padding-top: 30px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 20px 0;
      font-size: 16px !important;
      color: rgb(102, 102, 102) !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
    }
  }
}
.program_advantages{
  width: 100%;
  height: 380px;
  padding-top: 60px;
  text-align: center;
  .public-title{
    margin-top: 0;
  }
  .model_list2 {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .model_item{
      width: 300px;
      height: 210px;
      border-radius: 16px;
      padding: 15px 12px;
      .item_img{
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
      .item_info{
        margin-top: 10px;
      }
    }
  }
}
.display_terminal{
  width: 100%;
  height: 420px;
  padding-top: 60px;
  text-align: center;
  .public-title{
    height: 75px;
    margin-top: 0;
  }
  .modelList3{
    width: 1200px;
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    .model_item{
      padding: 15px 20px;
      position: relative;
      border-radius: 16px;
      width: 340px;
      height: 240px;
      .item_img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
      .item_info{
        position: absolute;
        top: 15px;
        left: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 210px;
        background: #000000;
        border-radius: 16px;
        opacity: 0.5;
        .info_title{
          font-size: 18px;
          font-weight: bold;
          color: #FFFFFF;
        }
        .info_text{
          margin-top: 10px;
          padding: 0 15px;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 16px;
        }
      }
    }
  }
}
.img_title {
    // width: 266px;
    margin: 0 auto;
    text-align: center;
    .theme--light.v-image {
      margin: 0px auto 30px;
      border-radius: 10px;
      width:400px ;
      height: 260px;
      object-fit: cover;
    }
    h2 {
      margin-top: 10px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
    }
    p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      margin-top: 5px;
      padding: 0px 45px
    }
}
::v-deep .look_btn{
  width: 120px;
  height: 40px !important;
  background: #0568FD !important;
  border-radius: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 13px;
}
::v-deep .v-carousel__controls{
  display: none !important;
}
.advantage_card{
  transition: 0.3s;
  &:hover {
      transform: scale(1.04);
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
}
</style>